












































import {
    Component,
    Vue,
    Watch,
} from 'vue-property-decorator';
import {
    Core
} from '@/store/core'
import {
    Auth
} from '@/store/auth'
import {
    User
} from '@/store/user'

import moment from "moment";
import { FarmForm } from "@/models/farm";
import {App} from "@/store/app";

@Component({
    components: {},
    computed: {}
})

export default class ImageClass extends Vue {
    api: any = process.env.VUE_APP_SERVER
    public formProfile: any = {}
    private response: boolean = false

    private async created() {
        await this.run();
    }

    private async run() {
        let buffalo = await Core.getHttp(`/user/buffalo/image/${this.$route.query.id}/`)
        if (buffalo.front_image) {
            let pathFront= this.api +'/'+ buffalo.front_image
            let frontImage: any = this.$refs.frontImage
            frontImage.src = (this.imageExists(pathFront))?pathFront:'./assets/buffalo/buffalo.png'
        }
        if (buffalo.back_image) {
            let pathBack = this.api +'/'+ buffalo.back_image
            let backImage: any = this.$refs.backImage
            backImage.src = (this.imageExists(pathBack))?pathBack:'./assets/buffalo/buffalo.png'
        }

        await Auth.setUser(this.formProfile.user)
        this.response = true
    }

    async frontChange(event: any) {
        let file = await Core.getBase64(event.target.files[0])
        let frontImage: any = this.$refs.frontImage
        frontImage.src = file
        let data = await Core.postHttp(`/user/buffalo/image/${this.$route.query.id}/`, { "front_image": file })
        if (data.id) {
          await App.success("อัพโหลดรูปภาพสำเร็จ")
        }
    }
    async backChange(event: any) {

        let file = await Core.getBase64(event.target.files[0])
        let backImage: any = this.$refs.backImage
        backImage.src = file
        let data = await Core.putHttp(`/user/buffalo/image/${this.$route.query.id}/`, { "back_image": file })
        if (data.id) {
          await App.success("อัพโหลดรูปภาพสำเร็จ")
        }
    }

    imageExists(image_url:any){

    var http = new XMLHttpRequest();

    http.open('HEAD', image_url, false);
    http.send();

    return http.status != 404;

}

}
