
























































































































































import { Component, Vue } from "vue-property-decorator";

import { Core } from "@/store/core";
import { Auth } from "@/store/auth";
import { User } from "@/store/user";
import { App } from "@/store/app";

import TabBuffaloData from "@/components/Component/Core/Buffalo/TabBuffaloData.vue";
import TabBuffaloEvo from "@/components/Component/Core/Buffalo/TabBuffaloEvo.vue";
import TabBuffaloCert from "@/components/Component/Core/Buffalo/TabBuffaloCert.vue";
@Component({
    components: {
        TabBuffaloData,
        TabBuffaloEvo,
        TabBuffaloCert,
    },
    computed: {},
    props: {
        color: {
            default: "light",
            validator: function (value) {
                // The value must match one of these strings
                return ["light", "dark"].indexOf(value) !== -1;
            },
        },
    },
})
export default class Table extends Vue {
    private openTab: number = 1;
    private date: string | null = null;
    private menu: boolean = false;

    private async created() {
        await this.run();
    }
    toggleTabs(tabNumber: number) {
        this.openTab = tabNumber;
    }

    private async run() {}

    async deleteBuffalo() {
        let checkDelete = await App.confirm("คุณแน่ใจใช่ไหม", "ที่จะลบควายตัวนี้");
        if (checkDelete) {
            let buffalo = await Core.deleteHttp(
                `/user/buffalo/buffalo/${this.$route.query.id}/`
            );

            await App.success("ลบควายสำเร็จ");
            await this.$router.go(-1);
        }
    }
}
